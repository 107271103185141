const collections = {
  Article: "articles/{countryCode}/articlesContent",
  Sponsors: "sponsors/{countryCode}/sponsorsContent",
  Verifiers: "verifiers/{countryCode}/verifiersContent",
  Offer: "offers/{countryCode}/offersContent",
  BirthStory: "birthStories/{countryCode}/birthStoriesContent",
  Podcasts: "podcasts/{countryCode}/podcastsContent",
  DietaryAdvices: "dietaryAdvices/{countryCode}/dietaryAdvicesContent",
  ArticleCategories: "articleCategories/{countryCode}/articleCategoriesContent",
  AppTakeOver: "appTakeOver/{countryCode}/appTakeOverContent",
  MonthlyInformation: "monthlyInformation/{countryCode}/content",
  FAQ: "faq/{countryCode}/faqContent",
  BlogCategories: "categories",
  //this has to be changed to the above line when the mobile is ready
  // BlogCategories: "categories/{countryCode}/categoriesContent",
  GeneralBlogPosts: "generalBlogPosts/{countryCode}/generalBlogPostsContent",
  ChildBlogPosts: "childBlogPosts/{countryCode}/childBlogPostsContent",
  DietaryAdvicesCategories:
    "dietaryAdviceCategories/{countryCode}/dietaryAdviceCategoriesContent",
  DietaryAdvicesContent:
    "dietaryAdviceContent/{countryCode}/dietaryAdviceContent",
  Banners: "banners/{countryCode}/bannersContent",
  ContentCollections:
    "contentCollections/{countryCode}/contentCollectionsContent",
  BlogPosts: "blogposts/{countryCode}/blogpostsContent",
  Influencers: "influencers/{countryCode}/influencersContent",
  WeeklyInformation: "weeklyInformation/{countryCode}/content",
  FaqCategories: "categoryItems/{countryCode}/faqCategories",
  Notifications: "notifications/{countryCode}",
  PregnancyTips: "tips/{countryCode}/pregnancyTips",
  ChildTips: "tips/{countryCode}/childTips",
  PregnancyMilestones:
    "milestones/{countryCode}/pregnancyMilestones/week/content",
  ChildMilestones: "milestones/{countryCode}/childMilestones",
  Popular: "popular/{countryCode}/popularContent",
  Recommended: "curatedContent/{countryCode}/contentScreen",
  Competitions: "competitions/{countryCode}/competitionsContent",
  Checklist: "checklists/{countryCode}/content",
  ContentMenu: "contentMenu/{countryCode}/contentMenuContent",
  MiniJourney: "miniJourney/{countryCode}/miniJourneyContent",
  Polls: "polls/{countryCode}/pollsContent",
  Highlights: "highlights/{countryCode}/highlightsContent",
  RemoteNotifications: "remoteNotifications/{countryCode}/notificationsContent",
  ToolsMenuBanner: "toolsMenu/{countryCode}/banners",
  ToolsMenuSection: "toolsMenu/{countryCode}/menuSections",
  AppLinks: "appLinks/{countryCode}/",
};

export const CollectionPaths = new Proxy(collections, {
  get(target, key) {
    return new Proxy(target, {
      get(_, countryCode) {
        return target[key as keyof typeof collections].replace(
          "{countryCode}",
          (countryCode as string).toLowerCase()
        );
      },
    });
  },
}) as unknown as {
  [Key in keyof typeof collections]: {
    [countryCode: string]: string;
  };
};
