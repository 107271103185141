import firebase, { firestore } from "../firebase";
import { NotificationComponentTypes } from "../utils/commonEnums";
import { firebaseCollectionNames } from "../utils/consts";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

const collectionPath = (countryCode: string, type: string) =>
  `${CollectionPaths.Notifications[countryCode]}/${type}`;

const document = "notificationContent";

const {
  notifications: {
    content: { children, pregnancy },
  },
} = firebaseCollectionNames;

export class NotificationService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  subscribePregnancyNotifications = (
    callback: (
      err: Error | null,
      snapshot: LocalPushNotificationPregnancy
    ) => void
  ) =>
    firebase
      .firestore()
      .collection(collectionPath(this.countryCode, pregnancy))
      .doc(document)
      .onSnapshot(
        snapshot => {
          const docs = snapshot.exists ? snapshot.data()?.content : {};
          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, {});
        }
      );

  subscribeChildrenNotifications = (
    callback: (
      err: Error | null,
      snapshot: LocalPushNotificationChildren
    ) => void
  ) =>
    firebase
      .firestore()
      .collection(collectionPath(this.countryCode, children))
      .doc(document)
      .onSnapshot(
        snapshot => {
          const docs = snapshot.exists ? snapshot.data()?.content : {};

          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, {});
        }
      );

  update = async (
    type: NotificationComponentTypes,
    content: LocalPushNotificationPregnancy | LocalPushNotificationChildren,
    merge = true
  ) => {
    firestore
      .collection(collectionPath(this.countryCode, type))
      .doc(document)
      .set(
        { content },
        {
          merge,
        }
      );
  };
}
