import styled from "styled-components";
import { Layout } from "antd";
import SiderNavigationBar from "../components/theme/components/SiderNavBar";
import { Content } from "antd/lib/layout/layout";
import { BJContainer } from "../components/theme/atoms/BJContainer";
import { FaExternalLinkAlt, FaPeopleArrows, FaRegBell } from "react-icons/fa";
import { Outlet } from "react-router";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "../components";
import { useNavigate } from "react-router-dom";
import {} from "../routes/pushNotificationsRouteConsts";
import {
  deepLink,
  notification,
  notificationChildren,
  notificationPregnancy,
} from "../routes";

export const NotificationMenuItems: MenuItemInfo[] = [
  {
    displayName: "Remote",
    url: "remote",
    icon: <FaPeopleArrows />,
    regionSupport: true,
  },
  {
    displayName: "Notification",
    url: notification,
    icon: <FaRegBell />,
    subMenu: [
      {
        key: "notification-pregnancy",
        value: notificationPregnancy,
        displayName: "Pregnancy",
        regionSupport: true,
      },
      {
        key: "notification-children",
        value: notificationChildren,
        displayName: "Children",
        regionSupport: true,
      },
    ],
    subMenuKey: notification,
    regionSupport: true,
  },
  {
    displayName: "Link Generator",
    url: deepLink,
    icon: <FaExternalLinkAlt />,
    regionSupport: true,
  },
];

export const PushNotificationsLayout = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <StyledLayout>
        <SiderNavigationBar items={NotificationMenuItems} />
        <StyledLayoutContent>
          <Content>
            <BJContainer>
              <Sentry.ErrorBoundary
                fallback={ErrorFallback}
                onReset={() => {
                  navigate("./");
                }}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </BJContainer>
          </Content>
        </StyledLayoutContent>
      </StyledLayout>
    </Layout>
  );
};

const StyledLayout = styled(Layout)`
  background-color: ${props => props.theme.white};
  height: 100vh;
`;

const StyledLayoutContent = styled(Layout)`
  padding: 1rem 1rem 1rem;
  margin-bottom: 2%;
`;
