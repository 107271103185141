import { useEffect, useState } from "react";
import { Form, Row, Col, DatePicker } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PodcastEpisodeMessages } from "../../../language";
import { CheckboxButton, FormModal } from "../../../components";
import { useCountry, usePodcast, useTags } from "../../../context";
import {
  DeepLinkType,
  capitalizeFirstLetter,
  formValidationError,
  getDuration,
  getFormattedErrorMessage,
  getMax,
} from "../../../utils";
import {
  BJSelectFormItem,
  BJInputFormItem,
  BJTagsFormItem,
  BJFormDatePicker,
} from "../../../components/theme";

interface Props {
  show: boolean;
  episode: PodcastEpisode | null;
  podcast: FullPodcast;
  seasons: PodcastSeason[];
  onHide: () => void;
}

type FormValues = {
  translations: {
    [key: string]: {
      name: string;
      description: string;
      url: string;
      tagWords: string[];
    };
  };
  seasonId: string;
  promoted: boolean;
  publishDate: string;
};

export const EpisodeModal = ({
  show,
  episode,
  podcast,
  onHide,
  seasons,
}: Props) => {
  const { primaryLocale, currentCountry } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          name: yup
            .string()
            .required(
              `Title (${item.key.toUpperCase()}): ${
                PodcastEpisodeMessages.requiredValidationText
              }`
            ),
          url: yup
            .string()
            .nullable()
            .url(
              `Audio Url (${item.key.toUpperCase()}): ${
                PodcastEpisodeMessages.urlValidationText
              }`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { id: podcastId, imageUrl: podcastImageUrl } = podcast;
  const { episodes, addEpisode, updateEpisode, deleteEpisode } =
    usePodcast(podcastId);
  const [error, setError] = useState<string | null>(null);
  const [tags, setTags] = useState<string[]>([]);

  const { filterUnnessaryTags } = useTags();
  useEffect(() => {
    if (!episode) {
      reset({
        promoted: false,
      });
    } else {
      reset({
        ...episode,
      });
    }
    setError(null);
  }, [episode, reset, show]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const { translations: translationsForm, seasonId } = data;
      const translations: PodcastEpisode["translations"] = {};
      Object.keys(translationsForm).forEach(async locale => {
        translations[locale] = {
          description: translationsForm[locale].description,
          url: translationsForm[locale].url,
          duration: 0,
          name: translationsForm[locale].name,
          tagWords: translationsForm[locale].tagWords,
        };
        try {
          translations[locale].duration = await getDuration(
            translationsForm[locale].url
          );
        } catch (e) {
          console.log(e);
        }
      });
      if (episode) {
        await updateEpisode(episode.id, {
          podcastId: podcastId,
          seasonId: seasonId,
          sortOrder: episode.sortOrder,
          tags: filterUnnessaryTags(tags),
          promoted: data.promoted ?? false,
          publishDate: data.publishDate,
          imageUrl: podcastImageUrl,
          translations,
        });
      } else {
        let highestSortOrder: number = getMax(episodes, "sortOrder");
        await addEpisode({
          podcastId: podcastId,
          seasonId: seasonId,
          sortOrder: highestSortOrder++,
          tags: filterUnnessaryTags(tags),
          promoted: data.promoted ?? false,
          publishDate: data.publishDate,
          imageUrl: podcastImageUrl,
          translations,
        });
      }
      onHide();
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const onDelete = async () => {
    if (!episode) {
      return;
    }
    try {
      await deleteEpisode(episode.id);
      onHide();
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };
  const { promoted } = watch();

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  const handleAudioValidation = (error: boolean) => {
    if (error) {
      setError("Invalid Url");
    } else {
      setError(null);
    }
  };
  return (
    <FormModal
      onHide={onHide}
      messageOnSubmit={false}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      errors={errors as any[]}
      onDelete={onDelete}
      enableDelete={!!episode}
      modalSubTitle={episode ? `Id - ${episode?.id}` : ""}
      modalTitle={episode ? "Update episode" : "Add episode"}
      size={"lg"}
      deepLink={{
        type: DeepLinkType.PodcastEpisode,
        id: episode?.id,
        params: { podcastId: episode?.podcastId },
        countryCode: currentCountry?.abb,
      }}
      localeSupported
    >
      {locale => (
        <Row gutter={50}>
          <Col md={12} lg={12}>
            <BJInputFormItem
              required
              control={control}
              error={!!errors?.translations?.[locale?.key]?.name?.message}
              label={`Title (${capitalizeFirstLetter(locale?.key)})`}
              message={errors?.translations?.[locale?.key]?.name?.message}
              fieldName={`translations.${locale?.key}.name`}
              key={`translations.${locale?.key}.name`}
            />
            <BJInputFormItem
              control={control}
              error={
                !!errors?.translations?.[locale?.key]?.description?.message
              }
              label={`Description (${capitalizeFirstLetter(locale?.key)})`}
              rows={6}
              message={
                errors?.translations?.[locale?.key]?.description?.message
              }
              fieldName={`translations.${locale?.key}.description`}
              {...register(`translations.${locale?.key}.description`)}
              key={`translations.${locale?.key}.description`}
            />

            {seasons.length > 0 && (
              <BJSelectFormItem
                {...register("seasonId")}
                size="large"
                control={control}
                error={!!errors.seasonId?.message}
                label={"Season"}
                message={errors.seasonId?.message}
                optionsList={seasons.map(season => ({
                  key: season.id,
                  value: season.id,
                  display: season.translations[primaryLocale?.key]?.title,
                }))}
                fieldName={"seasonId"}
              />
            )}

            <br />
            <Controller
              control={control}
              name="promoted"
              render={({ field: { onChange, value } }) => (
                <CheckboxButton
                  checked={value}
                  label="Promote"
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={12} lg={12}>
            <Form.Item>
              <BJInputFormItem
                {...register(`translations.${locale?.key}.url`)}
                required
                key={`translations.${locale?.key}.url`}
                control={control}
                error={!!errors?.translations?.[locale?.key]?.url?.message}
                label={`Audio Url (${capitalizeFirstLetter(locale?.key)})`}
                message={errors?.translations?.[locale?.key]?.url?.message}
                fieldName={`translations.${locale?.key}.url`}
              />
              <Controller
                {...register(`translations.${locale?.key}.url`)}
                key={`translations.${locale?.key}.url-audio`}
                name={`translations.${locale?.key}.url`}
                control={control}
                render={({ field: { value } }) => (
                  <Row>
                    <audio
                      id="episode-audio"
                      onError={() => handleAudioValidation(true)}
                      onCanPlayThrough={() => handleAudioValidation(false)}
                      controls
                      src={value}
                    >
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                  </Row>
                )}
              />
            </Form.Item>
            <BJTagsFormItem
              control={control}
              fieldName={`translations.${locale?.key}.tagWords`}
              label={`Tag words (${capitalizeFirstLetter(locale?.key)})`}
            />
            <BJFormDatePicker
              control={control}
              fieldName={"publishDate"}
              label="Publish date"
              message={errors?.publishDate?.message}
              error={!!errors?.publishDate?.message}
              format={"YYYY-MM-DD"}
              size={"large"}
            />
          </Col>
        </Row>
      )}
    </FormModal>
  );
};
