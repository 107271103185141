import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { useNotifications } from "../../context/NotificationContext";
import { newRoute } from "../../routes";
import { DisplayUnitType, NotificationComponentTypes } from "../../utils";
import { useCountry } from "../../context";
import remove from "lodash/remove";
import { BJContainer } from "../../components/theme";
import { Col, Row } from "antd";
import { FilterButton } from "../../components";

type periodType = "month" | "week";
export const NotificationList = ({
  type = NotificationComponentTypes.Pregnancy,
}: {
  type: NotificationComponentTypes;
}) => {
  const { notificationPregnancy, notificationChildren, loading } =
    useNotifications();
  const { primaryLocale } = useCountry();

  const navigate = useNavigate();

  const [originalTable, setOriginalTable] = useState<Notifications[]>([]);
  const defaultFilter = DisplayUnitType.Week;

  const [filterType, setFilterType] = useState<periodType>(defaultFilter);
  const [periodFilter, setPeriodFilter] = useState<IFilter>({
    data: [
      {
        text: "Week",
        value: DisplayUnitType.Week,
      },
      {
        text: "Month",
        value: DisplayUnitType.Month,
      },
    ],
    selected: new Set(),
  });

  useEffect(() => {
    const notificationTableSource: Notifications[] = (
      type === NotificationComponentTypes.Pregnancy
        ? notificationPregnancy
        : notificationChildren
    )
      .map(notification => ({
        ...notification,
        title: notification?.translations?.[primaryLocale.key]?.title ?? "",
        week:
          (notification as NotificationPregnancy).translations?.[
            primaryLocale.key
          ]?.week ?? 0,
        month:
          (notification as NotificationChildren).translations?.[
            primaryLocale.key
          ]?.month ?? 0,
        type:
          type === NotificationComponentTypes.Pregnancy
            ? "Pregnancy"
            : "Children",
        key: String(
          (notification as NotificationPregnancy).translations?.[
            primaryLocale.key
          ]?.week ??
            (notification as NotificationChildren).translations?.[
              primaryLocale.key
            ]?.month
        ),
      }))
      .filter(element => !!element[filterType]);
    remove(notificationTableSource, i => i.key === "undefined");
    setOriginalTable(notificationTableSource);
  }, [
    notificationChildren,
    notificationPregnancy,
    primaryLocale.key,
    type,
    filterType,
  ]);

  const onClickRow = (record: Notifications) => {
    return {
      onClick: () => {
        navigate(`${record.key}?key_type=${filterType}`);
      },
    };
  };

  const onChangeFilter = useCallback(
    (value: periodType) => {
      setFilterType(value);
      setPeriodFilter(prev => {
        const selected = prev.selected;
        if (selected.has(value)) {
          selected.delete(value);
        } else {
          selected.add(value);
        }
        return {
          data: prev.data,
          selected: selected,
        };
      });
    },
    [setFilterType, setPeriodFilter, periodFilter, filterType]
  );

  const renderListFilters = () => {
    return (
      <BJContainer>
        <Row wrap gutter={[20, 20]}>
          <Col>
            <FilterButton
              label="Filter By:"
              filter={periodFilter}
              onPressItem={value => onChangeFilter(value as periodType)}
              onCheckAll={() => console.log("onCheckAll")}
              disableCheckAll
              single
              defaultValue={periodFilter.data[0].text}
            />
          </Col>
        </Row>
      </BJContainer>
    );
  };
  const handleNewNotification = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<Notifications>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) =>
        record?.translations?.[primaryLocale.key]?.title.includes(
          value as string
        ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) => record.type.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title:
        type === NotificationComponentTypes.Pregnancy
          ? "Week"
          : filterType == "week"
          ? "Week"
          : "Month",
      dataIndex:
        type === NotificationComponentTypes.Pregnancy ? "week" : filterType,
      key: type === NotificationComponentTypes.Pregnancy ? "week" : filterType,
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];
  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New Notification"}
      title={"Notification"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewNotification}
      recordCountSuffix={"Notifications"}
      filters={
        type === NotificationComponentTypes.Children && renderListFilters()
      }
    />
  );
};
