import { Navigate, Route, Routes } from "react-router-dom";
import { RemoteNotificationsList } from "../../pages/RemoteNotification";
import { NotificationComponentTypes } from "../commonEnums";
import RemoteNotificationPage from "../../pages/RemoteNotification/RemoteNotificationPage";
import { PushNotificationsLayout } from "../../Layouts/PushNotificationsLayout";
import NotificationPage from "../../pages/Notification/NotificationPage";
import { NotificationList } from "../../pages/Notification";
import { DeepLink } from "../../pages/DeepLink";

export const PushNotificationsRoutes = () => (
  <Routes>
    <Route path={`/`} element={<PushNotificationsLayout />}>
      <Route path={""} element={<Navigate to="remote" />} />
      <Route path={"notification"}>
        <Route path={"notification-pregnancy"}>
          <Route
            path={""}
            element={
              <NotificationList type={NotificationComponentTypes.Pregnancy} />
            }
          />
          <Route
            path={"new"}
            element={
              <NotificationPage type={NotificationComponentTypes.Pregnancy} />
            }
          />
          <Route
            path={":key"}
            element={
              <NotificationPage type={NotificationComponentTypes.Pregnancy} />
            }
          />
        </Route>
        <Route path={"notification-children"}>
          <Route
            path={""}
            element={
              <NotificationList type={NotificationComponentTypes.Children} />
            }
          />
          <Route
            path={"new"}
            element={
              <NotificationPage type={NotificationComponentTypes.Children} />
            }
          />
          <Route
            path={":key"}
            element={
              <NotificationPage type={NotificationComponentTypes.Children} />
            }
          />
        </Route>
      </Route>
      <Route path={"/remote"}>
        <Route path={""} element={<RemoteNotificationsList />} />
        <Route path={"new"} element={<RemoteNotificationPage />} />
        <Route path={":id"} element={<RemoteNotificationPage />} />
      </Route>
      <Route path={"deepLink"} element={<DeepLink />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
);
