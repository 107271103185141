import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useChecklist,
  useChecklists,
  useCountry,
  useSponsors,
  useTags,
} from "../../context";
import { ConfirmationModal, FormEdit, FormEditType } from "../../components";
import { ChangeTaskOrderList } from "./ChangeTaskOrderList";
import { UpdateTaskModal } from "./Modal";
import {
  CheckListType,
  ContentType,
  DeepLinkType,
  formValidationError,
  isEqualArrays,
} from "../../utils";
import { commonErrors } from "../../language";
import { Row, Col, Space, List } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJSelectFormItem,
  BJTags,
  BJTagsFormItem,
} from "../../components/theme";
import { BJSwitchFormItem } from "../../components/theme/molecules/formItems/BJFormSwitch";
import { useTranslation } from "react-i18next";

type FormValues = {
  promoted: boolean;
  types: CheckListType[];
  translations: {
    [key: string]: {
      name: string;
      intro: string;
      tagWords: string[];
      ctaButtonText?: string;
    };
  };
  ctaButtonLink?: string;
  sponsorId: string;
};

const { requiredError } = commonErrors;

export const ChecklistPage = () => {
  const { id } = useParams<string>();
  const { checklist, loading } = useChecklist(id);
  const navigate = useNavigate();
  const { filterUnnessaryTags } = useTags();
  const { checklists, onUpdate, onDelete, onCreate } = useChecklists();
  const [isOrderMode, setIsOrderMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tasks, setTasks] = useState<ChecklistTask[]>([]);
  const [showAddTask, setShowAddTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState<ChecklistTask | null>(null);
  const { t } = useTranslation();
  const { sponsors } = useSponsors();
  const [tags, setTags] = useState<string[]>([]);
  const { primaryLocale, currentCountry } = useCountry();

  const schema = yup.object().shape({
    types: yup.array().min(1, requiredError).required(requiredError),
    ctaButtonLink: yup.string().notRequired(),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          name: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError2
              }`
            ),
          intro: yup.string(),
          link: yup.string().notRequired(),
          linkText: yup.string().when("link", {
            is: (link: string) => link,
            then: yup
              .string()
              .required(`(${String(item.label)}) Link Text is required`),
          }),
          ctaButtonText: yup.string().test({
            test: function (value) {
              const ctaButtonLink = watch("ctaButtonLink");
              if (ctaButtonLink && !value) {
                return this.createError({
                  message: `(${String(
                    item.label
                  )}) CTA Button Text is required`,
                });
              }
              return true;
            },
          }),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    formState,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (!checklist) {
      return;
    }
    const types: CheckListType[] =
      checklist?.types && checklist?.types.map(type => type);
    setTasks(checklist?.tasks);
    reset({
      translations: checklist?.translations ?? {},
      promoted: checklist?.promoted,
      types: (types as CheckListType[]) ?? [checklist?.type] ?? [
          CheckListType.pregnancy,
        ],
      sponsorId: checklist?.sponsorId,
      ctaButtonLink: checklist?.ctaButtonLink,
    });
    setTags(checklist?.tags);
  }, [checklist, primaryLocale, reset]);

  const onRemove = async () => {
    if (checklist) {
      onDelete(checklist.id);
      return navigate("./..");
    }
  };

  const onAddTask = (task: ChecklistTask) => {
    const sortOrderNumericArray = tasks.map(({ sortOrder }) => sortOrder);
    const highestSortOrder = Math.max(...sortOrderNumericArray);
    task.sortOrder = highestSortOrder + 1;
    setTasks(old => [...old, task]);
    setShowAddTask(false);
  };

  const onUpdateTask = (task: ChecklistTask) => {
    setTasks(old => old.map(t => (t.id === task.id ? task : t)));
    setSelectedTask(null);
  };

  const onDeleteTask = (id: Checklist["id"]) => {
    setTasks(old => old.filter(t => t.id !== id));
    setSelectedTask(null);
  };

  const onSaveChangeOrderTask = (tasks: ChecklistTask[]) => {
    setTasks(tasks);
    setIsOrderMode(false);
  };

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const types: CheckListType[] = data.types.map(type => type);
    const isRecordAvailable = checklists.find(
      x =>
        x.types === types &&
        x.id !== checklist?.id &&
        types.includes(CheckListType.gettingStarted)
    );
    const sponsor = sponsors.find(s => s.id === data.sponsorId);

    if (isRecordAvailable) {
      throw new Error(t("checklist:alreadyaddedInvitePartner"));
    }

    const translationsData = data?.translations;
    const translations = Object.entries(translationsData).reduce<
      typeof translationsData
    >((acc, [key, value]) => {
      return (acc[key] = { ...value, tagWords: value.tagWords ?? [] }), acc;
    }, {});

    if (checklist != null) {
      const updateData = {
        translations,
        sortOrder: checklist.sortOrder,
        tasks: tasks,
        tags: filterUnnessaryTags(tags),
        promoted: data.promoted ?? false,
        types: types,
        type: types[0],
        ctaButtonLink: data.ctaButtonLink,
        sponsor: sponsor ?? null,
        sponsorId: sponsor?.id ?? null,
      };
      await onUpdate(checklist.id, updateData);
    } else {
      const sortOrderNumericArray = checklists.map(
        ({ sortOrder }) => sortOrder
      );
      const highestSortOrder = Math.max(...sortOrderNumericArray);
      const { id } = await onCreate({
        translations,
        sortOrder: highestSortOrder + 1,
        tasks: tasks,
        tags: filterUnnessaryTags(tags),
        promoted: data.promoted ?? false,
        types: types,
        type: types[0],
        ctaButtonLink: data.ctaButtonLink,
        sponsor: sponsor ?? null,
        sponsorId: sponsor?.id ?? null,
      });
      return navigate(`../${id}`);
    }
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(checklist?.tasks ?? [], tasks) ||
    !isEqualArrays(checklist?.tags ?? [], tags);

  const recordIdentifier = watch(`translations.${primaryLocale?.key}.name`);

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        text="Do you really want to delete this checklist? This action cannot be undone."
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemove}
      />
      <UpdateTaskModal
        onAdd={onAddTask}
        show={selectedTask !== null || showAddTask}
        task={selectedTask}
        onDelete={onDeleteTask}
        onHide={() => {
          setSelectedTask(null);
          setShowAddTask(false);
        }}
        onUpdate={onUpdateTask}
      />
      <FormEdit
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={
          checklist
            ? checklist?.translations[primaryLocale?.key]?.name
            : "New Check List"
        }
        id={checklist?.id}
        editType={checklist?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        recordIdentifier={recordIdentifier}
        deepLink={{
          type: DeepLinkType.Checklist,
          id: checklist?.id,
          countryCode: currentCountry?.abb,
        }}
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <Row gutter={20}>
            <Col span={12}>
              <BJInputFormItem
                control={control}
                error={!!errors?.translations?.[locale?.key]?.name}
                label={`Title (${locale?.label})`}
                message={errors?.translations?.[locale?.key]?.name?.message}
                required={true}
                autoFocus
                fieldName={`translations.${locale?.key}.name`}
                key={`translations.${locale?.key}.name`}
              />
              <BJSelectFormItem
                defaultValue={[CheckListType.pregnancy]}
                control={control}
                error={!!errors.types}
                label={"Types"}
                message={errors.types && (errors.types as any).message}
                required={true}
                size={"large"}
                optionsList={[
                  {
                    key: CheckListType.pregnancy,
                    value: CheckListType.pregnancy,
                    display: "Pregnancy",
                  },
                  {
                    key: CheckListType.infant,
                    value: CheckListType.infant,
                    display: "Children 0-1",
                  },
                  {
                    key: CheckListType.children,
                    value: CheckListType.children,
                    display: "Children 1-2",
                  },
                ]}
                fieldName={"types"}
                multiple={true}
              />

              <BJMdFormItem
                fieldName={`translations.${locale.key}.intro`}
                key={`translations.${locale.key}.intro`}
                label={`Intro (${locale?.label ?? ""})`}
                control={control}
                required={false}
              />

              <BJInputFormItem
                control={control}
                error={!!errors?.translations?.[locale?.key]?.ctaButtonText}
                label={`CTA Button Text (${locale?.label})`}
                message={
                  errors?.translations?.[locale?.key]?.ctaButtonText?.message
                }
                fieldName={`translations.${locale?.key}.ctaButtonText`}
                key={`translations.${locale?.key}.ctaButtonText`}
              />
              <BJInputFormItem
                control={control}
                error={!!errors?.ctaButtonLink}
                label={`CTA Button Link`}
                message={errors?.ctaButtonLink?.message}
                fieldName={`ctaButtonLink`}
              />
              <BJSwitchFormItem
                control={control}
                label="Promoted"
                fieldName={"promoted"}
              />
              <BJTagsFormItem
                label={`Tag Words (${locale?.label ?? ""})`}
                key={`translations.${locale.key}.tagWords`}
                fieldName={`translations.${locale.key}.tagWords`}
                control={control}
              />
              <BJTags
                contentTags={tags}
                onChange={setTags}
                contentType={ContentType.Checklist}
              />

              <BJSelectFormItem
                size="large"
                control={control}
                error={!!errors?.sponsorId}
                label="Sponsor"
                message={errors?.sponsorId?.message}
                optionsList={sponsors.map(s => ({
                  key: s.id,
                  value: s.id,
                  display: s.name,
                }))}
                fieldName="sponsorId"
                includeEmpty
              />
            </Col>
            <Col span={12}>
              {isOrderMode ? (
                <ChangeTaskOrderList
                  initialTasks={tasks}
                  onCancel={() => setIsOrderMode(false)}
                  onSave={onSaveChangeOrderTask}
                />
              ) : (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-2 ">
                    <p className="m-0">Tasks</p>
                    <Space>
                      <BJButton
                        size="small"
                        buttonType={ButtonTypes.Save}
                        onClick={() => {
                          setShowAddTask(true);
                        }}
                      >
                        Add
                      </BJButton>
                      {tasks.length !== 0 ? (
                        <BJButton
                          size="small"
                          buttonType={ButtonTypes.Primary}
                          onClick={() => {
                            setIsOrderMode(true);
                          }}
                        >
                          Change order
                        </BJButton>
                      ) : null}
                    </Space>
                  </div>
                  <List bordered>
                    {tasks.map(task => (
                      <List.Item
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedTask(task)}
                        key={task.id}
                      >
                        {task?.translations[locale?.key]?.text}
                      </List.Item>
                    ))}
                  </List>
                </>
              )}
            </Col>
          </Row>
        )}
      </FormEdit>
    </>
  );
};
