import { Form, Typography } from "antd";
import { Control, Controller, Path } from "react-hook-form";
import { BJTagsInput } from "../../atoms/BJTagsInput";

export const BJTagsFormItem = <T,>({
  error,
  message,
  label,
  required,
  control,
  fieldName,
  disabled,
  extra,
}: {
  error?: boolean;
  message?: string;
  label: string;
  required?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
  autoFocus?: boolean;
  disabled?: boolean;
  extra?: string;
  type?: string;
}) => {
  return (
    <Form.Item
      label={label}
      name={fieldName}
      required={required}
      validateStatus={error && "error"}
      {...(message
        ? {
            help: (
              <Typography.Paragraph type="danger">
                {message}
              </Typography.Paragraph>
            ),
          }
        : undefined)}
      {...(extra
        ? {
            extra: (
              <Typography.Paragraph type="warning">
                {extra}
              </Typography.Paragraph>
            ) as JSX.Element,
          }
        : undefined)}
    >
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <BJTagsInput tags={value as string[]} onChange={onChange} />
        )}
      />
    </Form.Item>
  );
};
