import {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { NotificationService } from "../services";
import { NotificationComponentTypes } from "../utils/commonEnums";
import { useCountry } from "./CountryContext";
import { WEEK_STARTING_KEY_FIRESTORE } from "../utils";

type TimePeriodType = "week" | "month";
type ContextState = {
  notificationPregnancy: NotificationPregnancy[];
  notificationChildren: NotificationChildren[];
  loading: boolean;
  error: Error | null;
  updateNotifications: (
    key: number,
    locale: string,
    type: NotificationComponentTypes,
    data: NotificationPregnancy | NotificationChildren,
    periodType: TimePeriodType
  ) => void;
  getByKey: (
    key: number | string,
    type: NotificationComponentTypes,
    lang: string,
    periodType: TimePeriodType
  ) => NotificationPregnancy | NotificationChildren;
  deleteNotification: (
    key: number,
    locale: string,
    type: NotificationComponentTypes,
    periodType: TimePeriodType
  ) => void;
};

const NotificationContext = createContext<ContextState>({
  loading: false,
  error: null,
  notificationPregnancy: [],
  notificationChildren: [],
  updateNotifications: () => null,
  getByKey: () => null,
  deleteNotification: () => null,
});

export const NotificationProvider = ({ ...rest }) => {
  const [notificationPregnancy, setNotificationPregnancy] = useState<
    NotificationPregnancy[]
  >([]);
  const [notificationChildren, setNotificationChildren] = useState<
    NotificationChildren[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry, primaryLocale } = useCountry();

  const notificationService = useMemo(
    () => new NotificationService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const getNotifications = useCallback(
    (
      notifications:
        | LocalPushNotificationPregnancy
        | LocalPushNotificationChildren
    ): NotificationPregnancy[] | NotificationChildren[] =>
      Object.keys(notifications)?.length !== 0
        ? Object.entries(notifications).map(noti => noti[1])
        : [],
    []
  );

  const getPeriodKey = useCallback(
    (key: string | number, type: TimePeriodType) => {
      const convertedKey = typeof key == "number" ? key : parseInt(key);
      return type == "month"
        ? convertedKey
        : convertedKey + WEEK_STARTING_KEY_FIRESTORE;
    },
    [WEEK_STARTING_KEY_FIRESTORE]
  );

  useEffect(() => {
    setLoading(true);
    const unsubPregnancy = notificationService?.subscribePregnancyNotifications(
      (_error, Pregnancynotifications) => {
        setNotificationPregnancy(
          getNotifications(Pregnancynotifications) as NotificationPregnancy[]
        );
        setError(_error);
        setLoading(false);
      }
    );
    const unsubChildren = notificationService?.subscribeChildrenNotifications(
      (_error, Childrennotifications) => {
        setNotificationChildren(
          getNotifications(Childrennotifications) as NotificationChildren[]
        );
        setError(_error);
        setLoading(false);
      }
    );
    return () => {
      unsubPregnancy();
      unsubChildren();
    };
  }, [getNotifications, notificationService]);

  const getByKey = useCallback(
    (
      key: number | string,
      type: NotificationComponentTypes,
      lang: string,
      periodType: TimePeriodType
    ) => {
      if (loading) return null;
      let _notification: NotificationPregnancy | NotificationChildren = null;
      if (type === NotificationComponentTypes.Pregnancy) {
        _notification = notificationPregnancy.find(
          notification =>
            Number(notification?.translations?.[lang]?.week) === Number(key)
        );
      }
      if (type === NotificationComponentTypes.Children) {
        _notification = notificationChildren.find(notification => {
          const period_key =
            periodType ||
            (notification.translations[lang]["month"] ? "month" : "week");
          return (
            Number(notification.translations[lang][period_key]) === Number(key)
          );
        });
      }
      if (!_notification) return null;
      return _notification;
    },
    [loading, notificationChildren, notificationPregnancy]
  );

  const updateNotifications = useCallback(
    (
      key: number,
      locale: string,
      type: NotificationComponentTypes,
      data: NotificationPregnancy | NotificationChildren,
      periodType: TimePeriodType = "month"
    ) => {
      switch (type) {
        case NotificationComponentTypes.Pregnancy: {
          const payload: LocalPushNotificationPregnancy = {};
          for (const notifications of notificationPregnancy) {
            payload[notifications?.translations?.[locale]?.week] =
              notifications;
          }
          payload[key] = {
            ...payload[key],
            ...(data as NotificationPregnancy),
          };

          notificationService.update(type, payload);
          break;
        }
        case NotificationComponentTypes.Children: {
          const payload: LocalPushNotificationChildren = {};
          for (const notifications of notificationChildren) {
            const period_type = notifications?.translations?.[locale]?.month
              ? "month"
              : "week";
            const period_key = getPeriodKey(
              notifications?.translations?.[locale][period_type],
              period_type
            );
            payload[period_key] = notifications;
          }
          const childWeekMonthKey = getPeriodKey(key, periodType);
          payload[childWeekMonthKey] = {
            ...payload[childWeekMonthKey],
            ...(data as NotificationChildren),
          };
          notificationService.update(type, payload);
          break;
        }
        default: {
          break;
        }
      }
    },
    [notificationChildren, notificationPregnancy, notificationService]
  );

  const deleteNotification = useCallback(
    (
      key: number,
      locale: string,
      type: NotificationComponentTypes,
      periodType: TimePeriodType = "month"
    ) => {
      switch (type) {
        case NotificationComponentTypes.Pregnancy: {
          const payload: LocalPushNotificationPregnancy = {};
          for (const notifications of notificationPregnancy) {
            payload[notifications?.translations?.[locale]?.week] =
              notifications;
          }
          delete payload[key];

          notificationService.update(type, payload, false);
          break;
        }
        case NotificationComponentTypes.Children: {
          const payload: LocalPushNotificationChildren = {};
          for (const notifications of notificationChildren) {
            const period_type = notifications?.translations?.[locale]?.month
              ? "month"
              : "week";
            const period_key = getPeriodKey(
              notifications?.translations?.[locale][period_type],
              period_type
            );
            payload[period_key] = notifications;
          }
          const childWeekMonthKey = getPeriodKey(key, periodType);
          delete payload[childWeekMonthKey];
          notificationService.update(type, payload, false);
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      getPeriodKey,
      notificationChildren,
      notificationPregnancy,
      notificationService,
    ]
  );

  const value = useMemo(
    () => ({
      loading,
      error,
      notificationPregnancy,
      notificationChildren,
      updateNotifications,
      getByKey,
      deleteNotification,
    }),
    [
      loading,
      error,
      notificationPregnancy,
      notificationChildren,
      updateNotifications,
      getByKey,
      deleteNotification,
    ]
  );

  return <NotificationContext.Provider value={value} {...rest} />;
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within an NotificationProvider"
    );
  }
  return context;
};
