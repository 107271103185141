import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { FormEdit, FormEditType } from "../../components";
import { useNotifications, useCountry } from "../../context";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Divider, Row } from "antd";
import { commonErrors } from "../../language";
import { getMonths, getWeeks } from "../../utils/timeUtils";
import { formValidationError } from "../../utils/commonUtils";
import { Languages, NotificationComponentTypes } from "../../utils";
import { BJInputNumberFormItem } from "../../components/theme/molecules/formItems/BJInputNumberFormItem";
import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";
import { BJSelectFormItem, ButtonTypes } from "../../components/theme";
import BJButton from "../../components/theme/atoms/Button";
import firebase from "firebase/compat/app";
import { timelineOptions } from "../Milestone";
import { useLocation, useNavigate } from "react-router-dom";
import {
  notificationChildren,
  notificationsPath,
  notificationPregnancy,
} from "../../routes";

type TimePeriodtype = "week" | "month";

type SingleNotification = {
  translations: {
    [locale: string]: {
      title: string;
      body?: string;
    };
  };
  afterHowManyDays: number;
};

type FormValues = {
  translations: {
    [locale: string]: {
      title: string;
      body?: string;
    };
  };
  week?: number;
  month?: number;
  afterDays?: SingleNotification[];
  key: string;
  period?: number;
  periodType?: TimePeriodtype;
};

const { requiredError, uniqueNumbers } = commonErrors;

const NotificationSchema = (
  type: NotificationComponentTypes,
  currentCountry: Country
) => {
  const maxDays = type === NotificationComponentTypes.Pregnancy ? 6 : 30;
  return {
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
    week:
      type === NotificationComponentTypes.Pregnancy &&
      yup.string().when("periodType", {
        is: "week",
        then: yup.string().required(`Week: ${requiredError}`),
      }),
    month:
      type === NotificationComponentTypes.Children &&
      yup.string().when("periodType", {
        is: "month",
        then: yup.string().required(`Month: ${requiredError}`),
      }),
    afterDays: yup
      .array()
      .of(
        yup.object().shape({
          translations: yup.object().shape(
            currentCountry?.locales.reduce((acc, item) => {
              acc[item.key] = yup.object().shape({
                title: yup
                  .string()
                  .required(
                    `Title Notification (${String(
                      item.key
                    ).toUpperCase()}): ${requiredError}`
                  ),
              });
              return acc;
            }, {} as any)
          ),
          afterHowManyDays: yup
            .number()
            .typeError("It must be a number!")
            .required(`Show after how many days: ${requiredError}`)
            .min(1, `The number should be between 1 and ${maxDays}`)
            .max(maxDays, `The number should be between 1 and ${maxDays}`),
        })
      )
      .test("unique-afterDays", uniqueNumbers, function (value, ctx) {
        const numbers = value.map(item => item?.afterHowManyDays);
        const isValid = new Set(numbers).size === numbers.length;
        if (isValid) return true;
        return this.createError({
          path: "afterDays.0.afterHowManyDays",
          message: uniqueNumbers,
        });
      }),
  };
};

const NotificationPage = ({ type }: { type: NotificationComponentTypes }) => {
  const { primaryLocale, currentCountry } = useCountry();
  const navigate = useNavigate();

  const { key }: { key: string } = useParams();
  const key_type: TimePeriodtype = new URLSearchParams(
    useLocation().search
  ).get("key_type") as TimePeriodtype;
  const [language, setLanguage] = useState(primaryLocale.key);
  const { weeks } = getWeeks(language as Languages);

  const { months } = getMonths(language as Languages);

  const updatedWeeks = weeks.filter(e => e?.number < 14);
  const updatedMonths = months.filter(e => e?.number > 2);
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    control,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(
      yup.object().shape(NotificationSchema(type, currentCountry))
    ),
  });
  const periodType = watch("periodType");
  const { updateNotifications, getByKey, loading, deleteNotification } =
    useNotifications();

  const onSubmit = async ({
    translations,
    week,
    month,
    periodType,
    afterDays,
  }: FormValues) => {
    const pregnancyTranslations: NotificationPregnancy["translations"] = {};
    const childrenTranslations: NotificationChildren["translations"] = {};

    const notification = getByKey(
      type === NotificationComponentTypes.Pregnancy
        ? week
        : periodType === "month"
        ? month
        : week,
      type as NotificationComponentTypes,
      primaryLocale.key,
      type === NotificationComponentTypes.Pregnancy ? "week" : periodType
    );
    let payload = { ...notification };

    if (afterDays) {
      if (!payload.afterDays) {
        payload.afterDays = {};
      } else {
        const afterDaysKeys = new Set(
          afterDays.map(({ afterHowManyDays }) => afterHowManyDays.toString())
        );

        for (const existingKey in payload.afterDays) {
          if (!afterDaysKeys.has(existingKey)) {
            // it's needed to delete a key from object
            payload.afterDays[existingKey] =
              firebase.firestore.FieldValue.delete();
          }
        }
      }

      afterDays.forEach(({ translations, afterHowManyDays }) => {
        const afterDaysTrans: any = {};
        for (const [key, value] of Object.entries(translations)) {
          afterDaysTrans[key] = {
            title: value.title,
            body: value.body ?? "",
          };
        }
        if (!payload.afterDays[afterHowManyDays]) {
          payload.afterDays[afterHowManyDays] = {
            translations: afterDaysTrans,
          };
        } else {
          payload.afterDays[afterHowManyDays] = {
            translations: {
              ...payload.afterDays[afterHowManyDays].translations,
              ...afterDaysTrans,
            },
          };
        }
      });
    }

    for (const [key, value] of Object.entries(translations)) {
      if (type === NotificationComponentTypes.Pregnancy) {
        pregnancyTranslations[key] = {
          title: value.title,
          body: value.body,
          week,
        };
      }
      if (type === NotificationComponentTypes.Children) {
        childrenTranslations[key] = {
          title: value.title,
          body: value.body,
          [periodType]: periodType == "month" ? month : week,
        };
      }
    }

    if (type === NotificationComponentTypes.Pregnancy) {
      if (notification) {
        payload.translations = pregnancyTranslations;
      } else {
        const afterDays = payload.afterDays;
        payload = {
          translations: pregnancyTranslations,
          afterDays: afterDays ?? undefined,
        } as NotificationPregnancy;
      }
    }
    if (type === NotificationComponentTypes.Children) {
      if (notification) {
        payload.translations = childrenTranslations;
      } else {
        const afterDays = payload.afterDays;
        payload = {
          translations: childrenTranslations,
          afterDays: afterDays ?? undefined,
        } as NotificationChildren;
      }
    }

    updateNotifications(
      type === NotificationComponentTypes.Pregnancy
        ? week
        : periodType == "month"
        ? month
        : week,
      primaryLocale.key,
      type as NotificationComponentTypes,
      payload as unknown as NotificationPregnancy | NotificationChildren,
      periodType
    );
  };

  const { afterDays, translations, month, week } = watch();

  const afterDaysLength = useMemo(() => {
    const notification = getByKey(
      type === NotificationComponentTypes.Pregnancy
        ? week
        : periodType == "month"
        ? month
        : week,
      type as NotificationComponentTypes,
      primaryLocale.key,
      type === NotificationComponentTypes.Pregnancy ? "week" : periodType
    );

    if (notification && notification.afterDays) {
      return Object.keys(notification.afterDays).length;
    }
    return 0;
  }, [getByKey, type, week, month, primaryLocale.key, periodType]);

  const isDirty =
    !!Object.keys(dirtyFields).length ||
    (afterDaysLength && afterDaysLength !== afterDays?.length);

  useEffect(() => {
    const translations: any = {};
    const pageType =
      (type === NotificationComponentTypes.Pregnancy
        ? week
        : periodType == "month"
        ? month
        : week) || key;
    const notification = getByKey(
      pageType,
      type as NotificationComponentTypes,
      primaryLocale.key,
      type === NotificationComponentTypes.Pregnancy
        ? "week"
        : periodType
        ? periodType
        : key_type
    );
    if (notification) {
      const afterDays: SingleNotification[] = [];
      if (notification.afterDays) {
        Object.entries(notification.afterDays).forEach(([key, value]) => {
          afterDays.push({
            afterHowManyDays: Number(key),
            translations: value.translations,
          });
        });
      }

      for (const [key, value] of Object.entries(
        notification?.translations as
          | NotificationChildren["translations"]
          | NotificationPregnancy["translations"]
      )) {
        translations[key] = {
          title: value.title,
          body: value.body,
        };

        reset({
          ...value,
          periodType: periodType || (value?.week ? "week" : "month"),
          translations: translations,
          afterDays,
        });
      }
    } else {
      const defaultPeriodType =
        periodType ||
        (type === NotificationComponentTypes.Pregnancy ? "week" : "month");
      reset({
        periodType: defaultPeriodType,
        [defaultPeriodType]:
          pageType || (defaultPeriodType === "month" ? 3 : 1),
        afterDays: [] as any,
      });
    }
  }, [
    getByKey,
    key,
    month,
    primaryLocale.key,
    reset,
    setValue,
    type,
    week,
    periodType,
  ]);

  const handleAddAfterDaysNotification = (locale: Locale) => {
    const notification: SingleNotification = {
      translations: {
        [locale.key]: {
          title: "",
          body: "",
        },
      },
      afterHowManyDays: 0,
    };
    setValue(
      "afterDays",
      afterDays ? [...afterDays, notification] : [{ ...notification }],
      {
        shouldDirty: true,
        shouldValidate: false,
      }
    );
  };

  const handleRemoveAfterDaysNotification = (
    notifications: SingleNotification
  ) => {
    const translations: any = {};

    const modified = afterDays.filter(
      item => item.afterHowManyDays !== notifications.afterHowManyDays
    );
    const notification = getByKey(
      type === NotificationComponentTypes.Pregnancy
        ? week
        : periodType == "month"
        ? month
        : week,
      type as NotificationComponentTypes,
      primaryLocale.key,
      type === NotificationComponentTypes.Pregnancy ? "week" : periodType
    );
    if (notification) {
      for (const [key, value] of Object.entries(
        notification?.translations as
          | NotificationChildren["translations"]
          | NotificationPregnancy["translations"]
      )) {
        translations[key] = {
          title: value.title,
          body: value.body,
        };

        reset({
          ...value,
          periodType: periodType || (value?.week ? "week" : "month"),
          translations: translations,
          afterDays: modified,
        });
      }
    }
  };

  const renderAfterDays = (locale: Locale) => {
    return (
      <>
        {afterDays &&
          afterDays.length > 0 &&
          afterDays.map((notification, key) => {
            return (
              <Row key={key}>
                <Col xs={24}>
                  <BJInputNumberFormItem
                    control={control}
                    label={"Show after how many days"}
                    message={
                      errors?.afterDays?.[key]?.afterHowManyDays?.message
                    }
                    required={true}
                    min={1}
                    max={6}
                    precision={1}
                    fieldName={`afterDays.${key}.afterHowManyDays`}
                  />
                </Col>
                <Col xs={24}>
                  <BJInputFormItem
                    label={`Title (${locale?.label ?? ""})`}
                    fieldName={`afterDays.${key}.translations.${locale.key}.title`}
                    key={`afterDays.${key}.translations.${locale.key}.title`}
                    control={control}
                    error={
                      !!errors?.afterDays?.[key]?.translations?.[locale.key]
                        ?.title
                    }
                    message={
                      errors?.afterDays?.[key]?.translations?.[locale.key]
                        ?.title.message
                    }
                    required={true}
                  />
                </Col>
                <Col xs={24}>
                  <BJInputFormItem
                    label="Body"
                    fieldName={`afterDays.${key}.translations.${locale.key}.body`}
                    key={`afterDays.${key}.translations.${locale.key}.body`}
                    control={control}
                    error={
                      !!errors?.afterDays?.[key]?.translations?.[locale.key]
                        ?.body
                    }
                    message={
                      errors?.afterDays?.[key]?.translations?.[locale.key]?.body
                        ?.message
                    }
                  />
                </Col>
                <BJButton
                  size="small"
                  buttonType={ButtonTypes.Delete}
                  onClick={() =>
                    handleRemoveAfterDaysNotification(notification)
                  }
                >
                  Remove notification
                </BJButton>
                <Divider />
              </Row>
            );
          })}
      </>
    );
  };

  const handleOnRemove = async () => {
    await deleteNotification(
      type === NotificationComponentTypes.Pregnancy
        ? week
        : periodType == "month"
        ? month
        : week,
      primaryLocale.key,
      type as NotificationComponentTypes,
      periodType
    );
    return navigate(
      `${notificationsPath}/notification/${
        type === NotificationComponentTypes.Pregnancy
          ? notificationPregnancy
          : notificationChildren
      }`
    );
  };

  return (
    <FormEdit
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={
        !translations?.length
          ? "New Notification"
          : `translations.${primaryLocale.key}.title`
      }
      editType={key ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={`${translations?.[primaryLocale.key]?.title}`}
      localeSupported
      errors={errors as any}
      onRemove={handleOnRemove}
      deleteMessageTitle="Notification deletion was successfully requested"
      deleteMessage="New state will be updated after the notification is removed."
      deleteConfirmationMessage="This will remove the notification, are you sure?"
      deleteButtonText="Delete notification"
    >
      {locale => (
        <Row justify="space-between">
          <Col md={24} lg={11}>
            {setLanguage(locale.key)}
            <Row gutter={{ md: 20 }}>
              <Col xs={24}>
                {type === NotificationComponentTypes.Children ? (
                  <>
                    <BJSelectFormItem
                      control={control}
                      fieldName={"periodType"}
                      label={"Period Type(week/month)"}
                      error={false}
                      message={errors.periodType?.message}
                      required
                      optionsList={timelineOptions}
                    />
                    <BJSelectFormItem
                      control={control}
                      error={!!errors?.[periodType || "month"]}
                      label={periodType === "week" ? "Week" : "Month"}
                      message={
                        periodType === "week"
                          ? errors?.week?.message
                          : errors?.month?.message
                      }
                      optionsList={
                        periodType === "week"
                          ? updatedWeeks.map(week => ({
                              key: week.number,
                              value: week.number,
                              display: `${week.name}`,
                            }))
                          : updatedMonths.map(month => ({
                              key: month.number,
                              value: month.number,
                              display: `${month.name}`,
                            }))
                      }
                      fieldName={periodType || "month"}
                    />
                  </>
                ) : (
                  <BJSelectFormItem
                    control={control}
                    error={!!errors?.week}
                    label={"Week"}
                    message={errors?.week?.message}
                    optionsList={weeks.map(week => ({
                      key: week.number,
                      value: week.number,
                      display: `${week.name}`,
                    }))}
                    fieldName={"week"}
                  />
                )}

                <BJInputFormItem
                  label={`Title (${locale?.label ?? ""})`}
                  fieldName={`translations.${locale.key}.title`}
                  key={`translations.${locale.key}.title`}
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.title}
                  message={errors?.translations?.[locale.key]?.title?.message}
                  required={true}
                />
                <BJInputFormItem
                  label={`Body (${locale?.label ?? ""})`}
                  fieldName={`translations.${locale.key}.body`}
                  key={`translations.${locale.key}.body`}
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.body}
                  message={errors?.translations?.[locale.key]?.body?.message}
                />
              </Col>
            </Row>
          </Col>
          <Col md={24} lg={11}>
            {renderAfterDays(locale)}

            <BJButton
              size="small"
              buttonType={ButtonTypes.Primary}
              onClick={() => handleAddAfterDaysNotification(locale)}
            >
              Add notification
            </BJButton>
            <Divider />
          </Col>
        </Row>
      )}
    </FormEdit>
  );
};

export default NotificationPage;
